import {ice} from 'icepath'
import {AJAX_ERROR} from 'startlibs'

const erorrsOrSuccess = (success,errors) => Object.keys(errors).length ? {errors} : success

export const required = (s, k) => !s && 'Required field(s).'

export const minLength = (n) => (s, k) => s && s.length < n && `Field must have at least ${n} characters.`

export const confirmPasswords = (passwordKey) => (confirm, k, props) => (props[passwordKey] || confirm) && props[passwordKey] !== confirm && 'Passwords do not match.'
export const confirmEmails = (emailKey) => (confirm, k, props) => (props[emailKey] || confirm) && props[emailKey] !== confirm && 'Emails do not match.'

const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
export const emailValidation = (s, k) => !emailRegex.test(s) && 'Invalid email address.'

export const buildValidation = (validations,transform) => (props) =>
  erorrsOrSuccess({success: transform ? transform(props) : props},
    ice(validations)
      .map((v, k) => [].concat(v).map(fn => fn(props[k], k, props)).filter(_ => _))
      .filter((v, k) => v.length)
      .value()
  )


export const responseFailure = (form,fn) => (_,{response},n,{setErrors}) => {
  const errorSetter = fn ? form.connector.setErrors : setErrors
  if (response) {
    const error = (fn || form)(response)
    if (error) {
      errorSetter(error)
    } else {
      errorSetter({"":[AJAX_ERROR]})
    }
  }
}
