import React from 'react'
import styled from 'styled-components'
import {SignInLayout, Card, CardHeader, CardActionButton} from '../components/SigninLayout'
import {Icon} from 'startlibs/lib/components'

const AccessDeniedPage = styled(SignInLayout) `
  color: rgba(0,0,0,0.5);
  h2 {
    margin-bottom: 0.5rem;
    color: rgba(0,0,0,0.7);
    font-size: 22px;
  }
  ${Icon} {
    font-size: 90px;
    margin-bottom: 0.75rem;
    display: block;
  }
`

export class AccessDenied extends React.PureComponent {

  render() {
    const {system} = this.props
    return <AccessDeniedPage system={system}>
      <Card centralizedContent>
          <Icon icon="warning"/>
          <h2>Access denied</h2>
          <p>Please contact your administrator for more details.</p>
      </Card>
    </AccessDeniedPage>
  }

}
