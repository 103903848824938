import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from 'startlibs/lib/components'
import {withForm, Errors, Field, Form, TextInput, SimpleCheckbox, WithForm} from 'startlibs/lib/form'
import {getFetcher, formFetch} from 'startlibs'
import {withToggles} from 'startlibs/lib/hocs'
import {bearerHeader, logout, signIn, verify2FA} from '../reducers'
import {buildValidation, required, responseFailure} from '../lib/validation'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {ice} from 'icepath'
import styled from 'styled-components'
import {
  SignInLayout,
  Card,
  CardHeader,
  AdditionalActionButtons,
  CardActionButton,
  SignInMessageBox
} from '../components/SigninLayout'
import {RedefinePasswordForm} from './RedefinePassword'
import {getColor} from 'startlibs/lib/lib'

const SignInCard = styled(Card)`
  max-width: 35rem;
  margin: auto;
`

const ResendCodeMessage = styled.div`
  color: rgba(0,0,0,0.5);
  margin-top: 1rem;
  p {
    margin-bottom: 0.25rem;
  }
  .link {
    font-weight: 600;
  }
  .detail {
    font-size: 12px;
  }
  .loading {
    color: ${getColor('main')};
    font-weight: 600;
  }
  .success {
    color: ${getColor('success')};
  }
`

const ButtonsContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`

@withRouter
@withToggles('loading', 'resentCode', 'resendingCode')
@withForm(verify2FA)
@connect(undefined,{logout})
export class TwoFactorAuthentication extends React.Component {

  onFailure = (...args) => {
    const {response} = args[1]
    if (response.status === 403) {
      this.props.passwordExpired(response.params.key)
    } else if (response.status === 401) {
      this.logout(true)
    } else {
      responseFailure(
        ({detail, message, status}) =>
          ((status === 400) && {'oneTimePassword': [`Invalid or expired code.`]})
      )(...args)
    }
  }

  logout = (expired) => {
    this.props.logout()
    this.props.history.push(`/?systemId=${this.props.system.systemId}&username=${this.props.username}`,{loginKey:Date.now(),expiredSession:expired})
  }

  onSuccess = (_, {id_token}) => {
    this.props.loading.open()
    this.props.concludeLogin(id_token)
  }

  preValidation = buildValidation({
    oneTimePassword: [required]
  })

  resend = () => {
    this.props.resentCode.close()
    this.props.resendingCode.open()
    getFetcher('/pasapi/generate-2fa-code', undefined, bearerHeader(this.props.preAuthToken))
      .then(() => {
        this.props.resentCode.open()
        this.props.resentCode.closeAfter(30000)()
        this.props.resendingCode.close()
      })
  }

  render() {
    const {system, form, loading, preAuthToken, resendingCode, resentCode} = this.props
    return (
      <SignInLayout system={system}>
        <SignInCard>
          <CardHeader>
            <h1>Two-Factor Authentication</h1>
            <p>A temporary code was just sent to your e-mail.</p>
          </CardHeader>
          <Form
            alwaysSave
            preValidation={this.preValidation}
            onSuccess={this.onSuccess}
            onFailure={this.onFailure}
            form={form}
            id_token={preAuthToken}
          >
            <TextInput
              tabIndex={1}
              label="Code"
              constraint="###############"
              placeholder="Enter the code here"
              form={form}
              path="oneTimePassword"
            />
            <Errors form={form}/>
            <ResendCodeMessage>
                {resentCode.isOpen ?
                <p className="success">New email successfully sent.</p>
                :
                <p>Did not get the email? {resendingCode.isOpen ? <b className="loading">Sending...</b> : <span><a className="link" onClick={this.resend}>Resend it</a>.</span>}</p>
                }
              <p className="detail">Please also check your spam or junk folder if necessary.</p>
            </ResendCodeMessage>
            <ButtonsContainer>
              <Button tabIndex={2} onClick={() => this.logout()} type="button">Cancel</Button>
              <Button highlight isLoading={form.isLoading || loading.isOpen} tabIndex={1} type="submit">Next</Button>
            </ButtonsContainer>
          </Form>
        </SignInCard>
      </SignInLayout>
    )
  }
}
