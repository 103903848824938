import React from 'react'
import {Button, FlipControl, Icon} from 'startlibs/lib/components'
import {
  WithForm,
  withEditableBox,
  EditableBox,
  Errors,
  Field,
  Form,
  TextInput,
  SimpleCheckbox
} from 'startlibs/lib/form'
import {getFetcher, formFetch} from 'startlibs'
import {withToggles} from 'startlibs/lib/hocs'
import {formFetchWithAuthorization, logout, set2FA, setEmail, setName, signIn} from '../reducers'
import {buildValidation, confirmPasswords, emailValidation, required, responseFailure} from '../lib/validation'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {
  SignInLayout,
  Card,
  CardHeader,
  AdditionalActionButtons,
  FieldRequirements,
  Toggle,
  ToggleContainer
} from '../components/SigninLayout'
import styled from 'styled-components'
import {PasswordInput} from '../components/PasswordInput'
import {SupportMessage} from '../components/SupportMessage'

const LockedIcon = styled(Icon)`
  user-select: none;
  position: absolute;
  outline: none;
  bottom: 0;
  font-size: 21px;
  line-height: 3rem;
  right: 0;
  width: 2rem;
  pointer-events: none;
  color: #969696;
`

const DEFAULT_FORM = {rememberMe: false}

@withRouter
@withToggles('accountSettings', 'visiblePassword')
@withEditableBox()
@connect(({user}) => ({user}), {signIn, logout})
export class AccountSettings extends React.Component {

  logout = () => {
    this.props.logout()
    this.props.history.push(`/?systemId=${this.props.system.systemId}&username=${this.props.user.username}`,{loginKey:Date.now(),expiredSession:true})
  }

  onFailure = (...args) => {
    const {response} = args[1]
    if (response.status === 401) {
      this.logout()
    } else {
      responseFailure(
        ({detail, title, type, message}) =>
          (title === 'Incorrect password' && {password: [title + '.'], currentPassword: [title + '.']}) ||
          (title === 'passwordCannotBeEqualToThePreviousPasswords' && {newPassword: [`Your new password must be different from your previous ${this.props.system.systemAuthPolicy.pwdInHistory} passwords.`]}) ||
          (type === 'https://pas.purview.net/problem/invalid-password' && {newPassword: []}) ||
          (message === 'error.canNotChange2FA' && {enabled: ['System has 2FA enabled for all users']})
      )(...args)
    }
  }

  emailPreValidation = buildValidation({
    password: [required],
    email: [required, emailValidation]
  })

  using2FAValidation = buildValidation({
    password: [required]
  })

  passwordPreValidation = buildValidation({
    currentPassword: [required],
    newPassword: [required, confirmPasswords('confirmNewPassword')]
  })

  namePreValidation = buildValidation({
    firstName: [required],
    lastName: [required]
  })

  render() {
    const {system, user, logout, visiblePassword} = this.props

    return (
      <SignInLayout hideSupportMessage system={system}>
        <Card>
          <CardHeader>
            <h1>User authentication settings</h1>
          </CardHeader>
          <Field label="Name">
            <WithForm action={setName} id_token={user.id_token} url="/pasapi/account" values={user}
                      preValidation={this.namePreValidation} onFailure={this.onFailure}>
              {() =>
                <EditableBox
                  path={[]}
                  view={({getValue}) => getValue('firstName') + ' ' + getValue('lastName')}
                  edit={() =>
                    <>
                      <TextInput
                        mandatory
                        path="firstName"
                        label="First name"
                      />
                      <TextInput
                        mandatory
                        path="lastName"
                        label="Last name"
                      />
                    </>
                  }
                />
              }
            </WithForm>
          </Field>
          <Field label="Email">
            <WithForm action={setEmail} id_token={user.id_token} values={{}} preValidation={this.emailPreValidation}
                      onFailure={this.onFailure}>
              {() =>
                <EditableBox
                  path={[]}
                  edit={() =>
                    <>
                      <TextInput
                        mandatory
                        path="email"
                        label="New email"
                        descText={<span>Your current email is: <b>{user.email}</b></span>}
                      />
                      <PasswordInput
                        path="password"
                        mandatory
                        label="Account password"
                        descText="For security reasons you need to enter your account password to change the email address."
                      />
                      <span className="field-description">In order for this change to take effect, you must confirm your email from a link that will be sent to the new registered address.</span>
                    </>
                  }
                  view={() => user.email}
                />
              }
            </WithForm>
            {user.newEmail &&
            <span className="post-field-description">Email change requested to <b>{user.newEmail}</b>. Click on the link sent to this address to effect the change.</span>}
          </Field>
          <Field label="Username">
            <div className="editable-box collapsed locked">
              {user.username}
              <LockedIcon icon="lock"/>
            </div>
          </Field>
          <Field label="Password">
            <WithForm action={formFetchWithAuthorization} preValidation={this.passwordPreValidation}
                      url="/pasapi/account/change-password" id_token={user.id_token} onFailure={this.onFailure}>
              {(form) =>
                <EditableBox
                  view={() => <i>Click to change your password</i>}
                  edit={() =>
                    <>
                      <PasswordInput
                        mandatory
                        path="currentPassword"
                        label="Current password"
                      />
                      <PasswordInput
                        mandatory
                        path="newPassword"
                        syncVisible={visiblePassword}
                        label="New password"
                      />
                      <PasswordInput
                        mandatory
                        path="confirmNewPassword"
                        syncVisible={visiblePassword}
                        label="Confirm new password"
                      />
                      <FieldRequirements hasErrors={!!form.errors['newPassword']}>
                        Your new password must be <b>{system.systemAuthPolicy.pwdMinimumLength} or more characters</b>,
                        containing at least <b>one uppercase letter</b>, one <b>number</b> and one <b>special
                        character</b>.
                      </FieldRequirements>
                    </>
                  }
                />
              }
            </WithForm>
          </Field>
          <Field label="Two-Factor Authentication">
            {
              system.using2FA
              ? <div className="editable-box collapsed locked">
                <i>Enabled for all users by the administrator</i>
                <LockedIcon icon="lock"/>
              </div>
              : <WithForm action={set2FA} id_token={user.id_token} values={{enabled: user.using2FA}}
                          preValidation={this.using2FAValidation}
                          onFailure={this.onFailure}>
                {(form) =>
                  <EditableBox
                    path={[]}
                    edit={(v) =>
                      <>
                        <p>After you log in with your password, you’ll be asked for a temporary code sent to your
                          email.</p>
                        <ToggleContainer onClick={() => form.utils.setValue('enabled', !form.properties.enabled)}>
                          <><b>Enabled</b><Toggle checked={form.properties.enabled}/></>
                        </ToggleContainer>
                        <PasswordInput
                          path="password"
                          mandatory
                          label="Account password"
                          descText="For security reasons you need to enter your account password to change this option."
                        />
                      </>
                    }
                    view={() =>
                      (user.using2FA && 'Enabled') ||
                      <i>Click to set up extra security with two-factor authentication</i>
                    }
                  />
                }
              </WithForm>
            }

          </Field>
        </Card>
        <SupportMessage system={system}/>
        <AdditionalActionButtons>
          {/*<Button outline small onClick={logout}>Sign out</Button>*/}
          <Button.a small highlight href={system.systemWebHooks.systemAuthWebHook + user.id_token}>
            {console.log(system) || system.labels.systemGoTo}
          </Button.a>
        </AdditionalActionButtons>
      </SignInLayout>
    )
  }
}
