import React from 'react'
import styled from 'styled-components'
import {Link, withRouter} from 'react-router-dom'
import {withToggles} from 'startlibs/lib/hocs'
import {Errors, Field, Form, SimpleCheckbox, TextInput, withForm} from 'startlibs/lib/form'
import {getFetcher, postFetcher} from 'startlibs'
import connect from 'react-redux/es/connect/connect'
import {formFetchWithAuthorization, registerWithJwt, signIn} from '../reducers'
import {buildValidation, confirmEmails, confirmPasswords, responseFailure} from '../lib/validation'
import {Button, SplitColumnsContainer} from 'startlibs/lib/components'
import {
  SignInLayout,
  Card,
  CardHeader,
  FieldRequirements,
  BelowFieldDescription,
  AdditionalInfo,
  CardActionButton
} from '../components/SigninLayout'
import {loginFailure} from './LoginForm'
import {PasswordInput} from '../components/PasswordInput'
import {ActivationSuccess} from './ActivationSuccess'
import jwt_decode from 'jwt-decode'
import {JwtLinkExpiredError, LinkExpiredError} from '../errors/LinkExpiredError'

const getErrorForField = (field) => {
  if (field === 'password') {
    return {password: []}
  }
  if (field === 'email') {
    return {email: ['Invalid email address.']}
  }
}

export const allRequired = (s, k) => !s && 'All fields are required.'

const TextInputMaybeLocked = ({value, form, path, ...rest}) =>
  value
    ? <TextInput
      {...rest}
      value={value}
      disabled
      locked
    />
    : <TextInput
      {...rest}
      tabIndex={1}
      form={form}
      path={path}
    />

@withRouter
@withToggles('success', 'loading', 'visiblePassword','expiration')
@withForm(registerWithJwt)
@connect(undefined, {signIn})
export class JwtRegistrationForm extends React.Component {

  onFailure = (_,response,n,errors) => {
    if (response.response.status === 401) {
      this.props.expiration.open()
      return
    }
    return responseFailure(
      ({detail, title, type, message, fieldErrors}) =>
        ((message === 'error.validation' || message === 'error.http.401') && Array.isArray(fieldErrors) && fieldErrors.reduce((acc, {field}) => ({...acc, ...getErrorForField(field)}), {})) ||
        (title === 'passwordCannotBeEqualToThePreviousPasswords' && {password: [`Your new password must be different from your previous ${this.props.system.systemAuthPolicy.pwdInHistory} passwords.`]}) ||
        (type === 'https://pas.purview.net/problem/invalid-password' && {password: []}) ||
        (message === 'error.emailexists' && {email: ['This email is already registered']})
    )(_,response,n,errors)
  }

  onSuccess = (_, {id_token}) => {
    this.props.loading.open()
    window.location = this.props.system.systemWebHooks.systemAuthWebHook + id_token
  }

  preValidation = buildValidation({
    password: [allRequired, confirmPasswords('newPassword')]
  })


  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.utils.submitProperties()
  }

  parsedJwt = jwt_decode(this.props.jwt)

  render() {
    const {expired, system, form, success, jwt, visiblePassword, loading, expiration} = this.props
    const {sub: email} = this.parsedJwt
    if (expired || expiration.isOpen) {
      const loginInfo = JSON.parse(this.parsedJwt.loginInfo)
      return <JwtLinkExpiredError system={system} signinUrl={loginInfo.url} />
    }

    return <SignInLayout system={system}>
      <Card>
        <CardHeader>
          <h1>Password registration</h1>
          <p>Please create a password to complete your account.</p>
        </CardHeader>
        <Form
          alwaysSave
          preValidation={this.preValidation}
          onSuccess={this.onSuccess}
          onFailure={this.onFailure}
          onSubmit={this.handleSubmit}
          form={form}
          jwt={jwt}
        >
          <TextInput
            label="Email"
            value={email}
            locked
            disabled
          />
          <SplitColumnsContainer>
            <Field label="Create password">
              <PasswordInput
                tabIndex={1}
                form={form}
                path="password"
                syncVisible={visiblePassword}
                withoutField
              />
            </Field>
            <Field label="Confirm new password">
              <PasswordInput
                tabIndex={1}
                onPaste={(e) => e.preventDefault()}
                form={form}
                path="newPassword"
                syncVisible={visiblePassword}
                withoutField
              />
            </Field>
          </SplitColumnsContainer>
          <FieldRequirements hasErrors={!!form.errors['password']}>
            Your new password must be <b>{system.systemAuthPolicy.pwdMinimumLength} or more characters</b>, containing at least <b>one uppercase letter</b>, one <b>number</b> and one <b>special character</b>
          </FieldRequirements>
          <Errors form={form}/>
          <CardActionButton
            isLoading={form.isLoading || loading.isOpen}
            className="highlight with-loader"
            tabIndex={1}
            type="submit"
            highlight
          >
            Register password
          </CardActionButton>
        </Form>
      </Card>
    </SignInLayout>
  }
}
