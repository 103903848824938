import React from 'react'
import {Card, SignInLayout, CardHeader, CardActionButton, FieldRequirements} from '../components/SigninLayout'
import {TextInput, WithForm, Errors} from 'startlibs/lib/form'
import {withToggles} from 'startlibs/lib/hocs'
import {formFetch} from 'startlibs'
import {Button} from 'startlibs/lib/components'
import {buildValidation, confirmPasswords, minLength, required, responseFailure} from '../lib/validation'
import {PasswordInput} from '../components/PasswordInput'

// http://localhost:9000/redefine-password?key=58365593372806686968&systemId=startlabs

const preValidate = (key) => buildValidation({
    confirmPassword: confirmPasswords('password'),
    password: [required, minLength(8)]
  },
  ({password}) => ({key: key, password})
)

const onSuccess = (system, keepLoading, autoLogin, successMsg) => (_, {id_token}) => {
  keepLoading.open()
  if(autoLogin === 'false'){
    successMsg.open()
  }else{
    window.location = system.systemWebHooks.systemAuthWebHook + id_token
  }
}

export const RedefinePasswordForm = withToggles('keepLoading','visiblePassword','successMsg')(({system, visiblePassword, header, tokenKey, keepLoading, autoLogin, successMsg}) => {

  const onFailure = responseFailure(
    ({detail, message, title, type, status}) =>
      (title === 'passwordCannotBeEqualToThePreviousPasswords' && {password: [`Your new password must be different from your previous ${system.systemAuthPolicy.pwdInHistory} passwords.`]}) ||
      (type === 'https://pas.purview.net/problem/invalid-password' && {password: []}) ||
      (message === "error.keyExpired" && {'': [<span>This page has expired. Please <a href={"/recovery?systemId="+system.systemId}>request a new recovery link</a> and try again.</span>]})
  )

  return <Card>
    {header && header()}
    <WithForm
      action={formFetch}
      url="/pasapi/account/reset-password/finish"
      onFailure={onFailure}
      onSuccess={onSuccess(system, keepLoading, autoLogin, successMsg)}
      preValidation={preValidate(tokenKey)}
    >{(form) =>
      <form onSubmit={form.utils.handleSubmit}>
        <PasswordInput
          mandatory
          syncVisible={visiblePassword}
          path="password"
          label="New password"
        />
        <PasswordInput
          mandatory
          syncVisible={visiblePassword}
          path="confirmPassword"
          label="Confirm password"
        />
        <FieldRequirements hasErrors={!!form.errors['password']}>
          Your new password must be <b>{system.systemAuthPolicy.pwdMinimumLength} or more characters</b>, containing at least <b>one uppercase letter</b>, one <b>number</b> and one <b>special character</b>
        </FieldRequirements>
        <Errors form={form}/>
        {!successMsg.isOpen && <CardActionButton highlight isLoading={form.isLoading || keepLoading.isOpen} type="submit">
          {autoLogin === "false" ? "Save" : "Save and sign in"}
        </CardActionButton>
        }
        {
          successMsg.isOpen &&
          <ul className="successContainer">
            <li>
              <span>Success! Your password has been updated.</span>
              {/* <span className="icon icon-x" onClick={() => { successMsg.close(); }}/> */}
            </li>
          </ul>
        }
      </form>
    }
    </WithForm>
  </Card>
})


export const RedefinePassword = (props) =>
  <SignInLayout system={props.system}>
    <RedefinePasswordForm
      {...props}
      tokenKey={new URLSearchParams(window.location.search).get('key')}
      header={() =>
        <CardHeader>
          <h1>Define new password</h1>
          <p>Enter and confirm your new password below</p>
        </CardHeader>
      }
    />
  </SignInLayout>
