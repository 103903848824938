'use strict';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}
require('es6-collections')
// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

require('url-search-params-polyfill');

// find, findIndex
(function (ArrayProto) {
  var find = function(type) {
    return function (predicate) {
      if (this === null) {
        throw new TypeError('"this" is null or not defined');
      }
      var o = Object(this);
      var len = o.length >>> 0;
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }
      var thisArg = arguments[1];
      for (var k = 0; k < len; k++) {
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return (type===0? kValue : k);
        }
      }
      return (type===0?undefined:-1);
    };
  };
  if (!ArrayProto.find) {
    ArrayProto.find = find(0);
  }

  if (!ArrayProto.findIndex) {
    ArrayProto.findIndex = find(1)
  }
})(Array.prototype);

// remove
(function (arr) {
  arr.forEach(function (item) {
    if (!item.remove) {
      item.remove = function remove() {
        this.parentNode.removeChild(this);
      }
    }
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

// matches closest
(function (ElementProto) {
  if (typeof ElementProto.matches !== 'function') {
    ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector || function matches(selector) {
        var element = this;
        var elements = (element.parentNode || element.document || element.ownerDocument).querySelectorAll(selector);
        var index = 0;

        while (elements[index] && elements[index] !== element) {
          ++index;
        }

        return Boolean(elements[index]);
      };
  }

  if (typeof ElementProto.closest !== 'function') {
    ElementProto.closest = function closest(selector) {
      var element = this;

      while (element && element.nodeType === 1) {
        if (element.matches(selector)) {
          return element;
        }

        element = element.parentNode;
      }

      return null;
    };
  }
})(Element.prototype);

Object.values = Object.values || function values(O) {
  return Object.keys(O).map(function(k){return O[k]})
};

Object.entries = Object.entries || function entries(O) {
  return Object.keys(O).map(function(k){return [k,O[k]]})
};

(function () {

  if ( typeof window.CustomEvent === "function"  || !window.Event) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();