import React from 'react'
import styled from 'styled-components'
import {TextInput, WithForm, Errors} from 'startlibs/lib/form'
import {withToggles} from 'startlibs/lib/hocs'
import {formFetch} from 'startlibs'
import {Link, Button} from 'startlibs/lib/components'
import {
  Card,
  CardHeader,
  SignInLayout,
  CardActionButton,
  AdditionalActionButtons,
  AdditionalActionText
} from '../components/SigninLayout'
import {buildValidation, emailValidation, responseFailure} from '../lib/validation'
import {SupportMessage} from '../components/SupportMessage'

const LinkContainer = styled.div `
  margin-top: 1rem;
  text-align: center;
`

const onFailure = responseFailure(({message, title, status}) =>
  (status === 404 && {
    username: [<React.Fragment>No account found using this username.</React.Fragment>]
  })
)

export const PasswordRecovery = withToggles('successFeedback')(({system, successFeedback}) =>
  <WithForm
    action={formFetch}
    formProperties={{systemId:system.systemId}}
    url="/pasapi/account/reset-password/init"
    onFailure={onFailure}
    onSuccess={(_,{email})=>successFeedback.open(email)}
  >{(form) =>
    <SignInLayout hideSupportMessage system={system}>
      <Card>
        <CardHeader>
          <h1>User password recovery</h1>
          <p>Fill in below and a recovery link will be sent to your email</p>
        </CardHeader>
        <form onSubmit={form.utils.handleSubmit}>
          <TextInput
            path="username"
            label="Username:"
            placeholder="Enter your username"
          />
          <Errors form={form}/>
          {
            successFeedback.isOpen &&
            <ul className="successContainer">
              <li>
                <span>Recovery link sent successfully to your email (<b>{successFeedback.isOpen}</b>).</span>
                <span className="icon icon-x" onClick={() => { successFeedback.close(); form.utils.setValue('username','') }}/>
              </li>
            </ul>
          }
          <CardActionButton type="submit" disabled={successFeedback.isOpen} isLoading={form.isLoading} highlight>
            Recover password
          </CardActionButton>
          <LinkContainer><Link className="link" skipTo="/" small>Back to sign in</Link></LinkContainer>
        </form>
      </Card>
      <SupportMessage system={system}/>
    </SignInLayout>
  }
  </WithForm>
)
