import React from 'react'
import styled from 'styled-components'

const StyledSupportMessage = styled.div `
  text-align: center;
  color: rgba(0,0,0,0.4);
  padding: 0.75rem 0 1rem;
  font-weight: 600;
  max-width: 35rem;
  margin: auto;
  a {
    text-decoration;
    color: rgba(0,0,0,0.4);
    :hover {
      color: rgba(0,0,0,0.6);
    }
  }
`


export const SupportMessage = ({system}) =>
  system.labels.supportContact
  ? <StyledSupportMessage dangerouslySetInnerHTML={{ __html: system.labels.supportContact}}/>
  : <StyledSupportMessage>Need support? Call (800) 501-1537 or email <a href="mailto:support@purview.net">support@purview.net</a></StyledSupportMessage>