import React from 'react'
import styled from 'styled-components'
import {SignInLayout, Card, CardHeader, CardActionButton} from '../components/SigninLayout'
import {Button} from 'startlibs/lib/components'

export class ActivationSuccess extends React.PureComponent {

  render() {
    const {system,token, newRegistration} = this.props
    return <SignInLayout system={system}>
      <Card centralizedContent>
        {
          newRegistration
            ? <CardHeader>
              <h1>Registration completed!</h1>
              <p>Thank you for completing your account information.</p>
            </CardHeader>
            : <CardHeader>
              <h1>Registration update completed!</h1>
              <p>Thank you for updating your account information.</p>
            </CardHeader>
        }
        <CardActionButton.a href={system.systemWebHooks.systemAuthWebHook+token} highlight>{system.labels.systemGoTo}</CardActionButton.a>
      </Card>
    </SignInLayout>
  }

}
