import {combineReducers} from 'redux'
import {ice} from 'icepath'
import {uiReducer,makeActionCreator,reducerCreator, postFetcher, getFetcher,arrToMap} from 'startlibs'

export const formPutFetch = makeActionCreator('FORM_FETCH', (s) => s, (obj, {url}) => postFetcher(url, obj,{method:"PUT"}))
export const deleteFetch = makeActionCreator('DELETE_FETCH', (s) => s, (obj, {url}) => postFetcher(url, obj, {method: "DELETE"}))

export const signIn = makeActionCreator("SIGN_IN",(chain,result)=>ice(result))
export const logout = makeActionCreator("LOGOUT",()=>ice({}))

export const bearerHeader = (idToken, contentType = 'application/json; charset=UTF-8') => ({headers: new Headers({'Content-Type': contentType,"Authorization":`Bearer ${idToken}`})})
export const TEXT_HTML = 'text/plain; charset=UTF-8'

export const formFetchWithAuthorization = makeActionCreator('FORM_FETCH', (s) => s, (obj, {url,id_token}) => postFetcher(url, obj,bearerHeader(id_token)))
export const verify2FA = makeActionCreator('FORM_FETCH', (s) => s, ({oneTimePassword}, {id_token}) => postFetcher('/pasapi/verify-2fa-code', oneTimePassword,bearerHeader(id_token)))
export const loginWithJwt = makeActionCreator('FORM_FETCH', (s) => s, ({password}, {jwt}) => postFetcher('/pasapi/authenticate-token/', password,bearerHeader(jwt,TEXT_HTML)))
export const registerWithJwt = makeActionCreator('FORM_FETCH', (s) => s, ({password}, {jwt}) => postFetcher('/pasapi/register-token', password,bearerHeader(jwt,TEXT_HTML)))

export const setName = makeActionCreator('SET_NAME',
  (s,p,{_payload}) => s.assign(_payload),
  ({firstName,lastName,email}, {id_token}) => postFetcher("/pasapi/account", {firstName,lastName,email},bearerHeader(id_token))
)
export const setEmail = makeActionCreator('SET_EMAIL',
  (s,p,{_payload:{email}}) => s.set('newEmail',email),
  ({email,password}, {id_token}) => postFetcher("/pasapi/account/email-change/init", {email,password},bearerHeader(id_token))
)
export const set2FA = makeActionCreator('SET_2FA',
  (s,p,{_payload:{enabled}}) => s.set('using2FA',enabled),
  ({enabled,password}, {id_token}) => postFetcher("/pasapi/account/change-2fa", {enabled,password},bearerHeader(id_token))
)
const userReducer = reducerCreator({},[signIn,logout,setName,setEmail,set2FA])

export const clearNotifications = makeActionCreator('CLEAR_NOTIFICATIONS', (chain) => ice([]))
export const setNotifications = makeActionCreator('SET_NOTIFICATIONS', (chain,notification) => ice(notification))
const notificationReducer = reducerCreator([], [clearNotifications,setNotifications])


export const reducers = combineReducers({
  user: userReducer(),
  appUI:uiReducer({
    notifications: notificationReducer()
  })
})
