import React from 'react'
import {Button, Icon} from 'startlibs/lib/components'
import styled from 'styled-components'

export const PageError = styled(({className}) => <div className={className}>
  <div className="content-box">
    <Icon icon="warning"/>
    <h2>This page could not be loaded</h2>
    <p>Please refresh the page and try again.<br/>If the problem persists, contact us.</p>
    <Button.Link to="/">Return to home</Button.Link>
    <Button.a href="." highlight>Refresh page</Button.a>
  </div>
</div>)`
  padding: 10rem 1rem 1rem;
  text-align: center;
  max-width: 40rem;
  margin: 0 auto;
  color: rgba(0,0,0,0.5);
  h2 {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }
  p {
    max-width: 25rem;
    margin: 0 auto 2rem;
  }
  ${Icon} {
    font-size: 70px;
  }
  .content-box {
    padding: 3rem 1rem;
    background-color: rgba(0,0,0,0.05);
    border-radius: 5px;
  }
  @media (max-width: 515px) {
    padding: 6rem 1rem;
    h2 {
      max-width: 16rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`
