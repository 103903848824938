export const getJWTPayload = (s) => {
  if (!s) return null
  const payloadB64 = s.split('.')[1]
  if (!payloadB64) return null
  try {
    return JSON.parse(window.atob(payloadB64))
  } catch (e) {
    return null
  }

}