import React from 'react'
import {withToggles} from 'startlibs/lib/hocs'
import {Field, TextInput} from 'startlibs/lib/form'
import {Icon} from 'startlibs/lib/components'
import styled from 'styled-components'

export const PasswordVisibilityButton = styled(Icon) `
  position: absolute;
  right: 1rem;
  bottom: 5px;
  font-size: 24px;
  color: rgba(0,0,0,0.5);
  cursor: pointer;
  :hover {
    color: rgba(0,0,0,0.75);
  }
`

const Container = styled.div`position:relative;`

export const PasswordInput = withToggles('visible')(({visible,syncVisible=visible,...props}) => <Container>
  <TextInput
    {...props}
    type={syncVisible.isOpen ? 'text' : "password"}
  />
  <PasswordVisibilityButton onClick={(e) => { syncVisible.toggle(); e.target.parentNode.querySelector('input').focus()}} icon={syncVisible.isOpen ? "hide" : "view"}/>
</Container>)